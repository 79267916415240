.green-text {
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 1.2;
    color: $green;
    text-decoration: none;
}

.booking {
    $black: #424547;
    $gray: #8c8c8c;
    $light-gray: #eee;

    background-color: #f2f2f2;
    padding-top: 1px;
    padding: 70px 0;
    font-family: 'Rubik';
    color: $black;

    @media (max-width: $tablet) {
        padding: 64px 0 30px;

        .booking-margin-inner-block {
            margin: 0 18px;
        }
    }

    @media (max-width: $mobile) {
        padding: 50px 0 30px;
        .button {
            line-height: 40px;
        }
    }

    [data-braintree-id="toggle"] {
        display: none;
    }

    .booking-top-message {
        $green: #2bb656;
        $red: #f44336;
        $yellow: #fcaa00;

        padding: 40px;
        color: white;
        text-align: center;
        margin-bottom: 20px;

        @media (min-width: $tablet + 1) {
            .content {
                width: 800px;
            }
        }

        &-header {
            font-family: 'Rubik';
            font-size: 19px;
            color: white;
            font-weight: 500;
            line-height: 1.5;

            // Rubic 19px, color: white, font-weight: 500; line-height: 1.5

        }

        a {
            color: white;
            text-decoration: none;
        }

        &-text {
            font-family: 'ProximaNova', sans-serif;
            font-size: 16px;
            color: white;
            line-height: 1.5;
            font-weight: 500;

            @media (max-width: $mobile) {
                padding: 20px 15px 20px 15px;
                font-size: 15px;
            }

            @media (min-width: $mobile + 1) and (max-width: $tablet) {
                padding: 40px 40px 20px 40px;
            }

            @media (min-width: $tablet + 1) {}

            // ProximaNova 16px, color: white, line-height: 1.5

        }

        &-green {
            background-color: $green;
        }

        &-red {
            background-color: $red;
        }

        &-yellow {
            background-color: $yellow;
        }
    }

    .decorated {
        font-weight: 400;
        margin: 40px 0;

        @media (max-width: $tablet) {
            margin: 30px 0;
        }

        div {
            font-weight: 700;
        }
    }

    .booking-margin-between-block {
        margin: 30px 0;

        @media (max-width: $tablet) {
            margin: 0;
        }
    }

    .booking-columns {
        @media (max-width: $tablet) {
            display: table;
            margin: auto;
        }

        > * {
            float: left;

            .booking-left-column-header {
                font-size: 19px;
                font-weight: 700;
                margin-bottom: 20px;
            }

            .booking-inner-block {
                padding: 20px 22px;
            }
        }

        a {
            @extend .green-text;

            &.tallinn-riga {
                color: $blue;
            }

            display: inline-block;
        }

        .booking-left-column {
            width: 514px;
            margin-right: 54px;
            margin-left: 74px;

            @media (max-width: $tablet) {
                display: table-footer-group;
                width: 100%;
                margin: 0;
            }

            .booking-payment-text {
                font-size: 14px;
                font-weight: 400;
                font-family: 'Rubik';
                margin-bottom: 20px;
            }

            .booking-payment-header {
                font-size: 19px;
                font-weight: 700;
            }

            .booking-double-inputs {
                > div {
                    float: left;
                    width: 257px;

                    &:nth-child(1) {
                        padding-right: 5px;

                        @media (max-width: $tablet) {
                            padding: 0;
                        }
                    }

                    &:nth-child(2) {
                        padding-left: 5px;

                        @media (max-width: $tablet) {
                            padding: 0;
                        }
                    }

                    @media (max-width: $tablet) {
                        float: none;
                        width: 100%;
                    }
                }

                &.booking-payment-methods {}
            }

            .booking-triple-inputs {
                > div {
                    float: left;

                    &:nth-child(1) {
                        width: 50%;

                        @media (max-width: $tablet) {
                            float: none;
                            width: 100%;
                        }
                    }

                    &:nth-child(2) {
                        width: 25%;

                        @media (max-width: $tablet) {
                            float: left;
                            width: 50%;
                        }
                    }

                    &:nth-child(3) {
                        width: 25%;

                        @media (max-width: $tablet) {
                            float: left;
                            width: 50%;
                        }
                    }
                }
            }

            .booking-inputs {
                box-sizing: border-box;
                padding: 10px;

                @media (max-width: $tablet) {
                    padding: 0;
                }

                .intl-tel-input {
                    @media (max-width: $tablet) {
                        width: 100%;
                        margin-bottom: 14px;
                    }

                    input {
                        &::-webkit-input-placeholder {
                            /* Chrome/Opera/Safari */
                            color: #ccc;
                        }

                        &::-moz-placeholder {
                            /* Firefox 19+ */
                            color: #ccc;
                        }

                        &:-ms-input-placeholder {
                            /* IE 10+ */
                            color: #ccc;
                        }

                        &:-moz-placeholder {
                            /* Firefox 18- */
                            color: #ccc;
                        }
                    }
                }

                label {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 10px;

                    .optional-span {
                        @media (max-width: $tablet) {
                            display: none;
                        }
                    }
                }

                input, textarea {
                    font-family: 'Rubik';
                    padding: 14px;
                    width: 100%;
                    box-sizing: border-box;
                    border: 1px solid #d9d9d9;
                    font-size: 16px;
                    color: #424547;
                    background-color: #FFF;

                    @media (max-width: $tablet) {
                        margin-bottom: 14px;
                    }

                    &:disabled {
                        font-family: 'Rubik';
                        padding: 14px;
                        width: 100%;
                        box-sizing: border-box;
                        border: 1px solid $light-gray;
                        font-size: 14px;
                        color: $gray;
                        background-color: #FFF;
                        -webkit-text-fill-color: $gray;
                        opacity: 1.0;
                    }

                    &#id_phone {
                        padding-left: 52px;
                    }
                }

                input[type="checkbox"] {
                    display: none;

                    &:not(:checked),
                    &:checked {
                        & + label {
                            $size: 15px;

                            @media (max-width: $tablet) {
                                margin-bottom: 20px;
                            }

                            line-height: 1.4;
                            padding-left: 33px;
                            position: relative;
                            -webkit-touch-callout: none;

                            /* iOS Safari */
                            -webkit-user-select: none;

                            /* Safari */
                            -khtml-user-select: none;

                            /* Konqueror HTML */
                            -moz-user-select: none;

                            /* Firefox */
                            -ms-user-select: none;

                            /* Internet Explorer/Edge */
                            user-select: none;

                            /* Non-prefixed version, currently
                               supported by Chrome and Opera */

                            &:before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: $size;
                                height: $size;
                                border: 2px solid #ccc;
                                background: #fff;
                                border-radius: 4px;
                                box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
                                transition: background 0.2s ease;
                                transition: border 0.2s ease;
                            }
                        }
                    }

                    &:checked {
                        & + label {
                            &:before {
                                background: $green;
                                border-color: forestgreen;
                            }
                        }
                    }
                }

                input[type="radio"] {
                    display: none;

                    &:not(:checked),
                    &:checked {
                        & + label {
                            $size: 15px;

                            @media (max-width: $tablet) {
                                margin-bottom: 20px;
                            }

                            line-height: 1.4;
                            margin-left: $size + 10px;
                            position: relative;
                            background-repeat: no-repeat;

                            // background-position: 30px center;

                            // width: 100%;
                            height: 35px;
                            background-size: contain;
                            -webkit-touch-callout: none;

                            /* iOS Safari */
                            -webkit-user-select: none;

                            /* Safari */
                            -khtml-user-select: none;

                            /* Konqueror HTML */
                            -moz-user-select: none;

                            /* Firefox */
                            -ms-user-select: none;

                            /* Internet Explorer/Edge */
                            user-select: none;

                            /* Non-prefixed version, currently
                               supported by Chrome and Opera */
                            &:before {
                                content: '';
                                position: absolute;
                                left: -($size + 10px);
                                top: 8px;
                                width: $size;
                                height: $size;
                                border: 2px solid #ccc;
                                background: #fff;
                                border-radius: 50%;
                                box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
                                transition: background 0.2s ease;
                                transition: border 0.2s ease;
                            }
                        }
                    }

                    &:checked {
                        & + label {
                            &:before {
                                background: $green;
                                border-color: forestgreen;
                            }
                        }
                    }
                }
            }

        }

        .cancellation_info {
            background: #c8edb6;
            color: #2bb656;
            margin-left: 10px;
            margin-right: 10px;
            padding: 14px;
            padding-left: 44px;
            margin-bottom: 8px;
            background-image: url("/static/img/icon_refund_green.svg");
            background-position: 14px center;
            background-repeat: no-repeat;

            @media (max-width: $tablet) {
                margin-left: 18px;
                margin-right: 18px;
            }
        }

        .booking-confirm-button {
            text-align: center;
            margin: 10px 0 40px;

            @media (max-width: $tablet) {
                margin: 10px 0 20px;
            }

            button {
                height: 44px;
                font-size: 16px;
                width: 91%;
                background-color: #2bb656;
                color: white;
                cursor: pointer;
                text-transform: uppercase;
                -webkit-transition: width 1s;

                /* Safari */
                transition: width 1s;
            }

            &.tallinn-riga {
                button {
                    background-color: $blue;
                    border-color: $blue;
                }
            }
        }
        
        .booking-right-column {
            @media (max-width: $tablet) {
                margin-top: 1em;
                display: table-header-group;
                width: 100%;
            }

            width: 283px;

            h3 {
                margin: 0;

                a {
                  text-align: left;
                }
            }

            .booking-right-column-header {
              font-size: 19px;
              font-weight: 700;
              margin: 16px 0 20px;
              padding-left: 18px;
            }


            .booking-margin-inner-block {
                background-color: #FFF;

                @media (max-width: $tablet) {
                    margin-bottom: 10px;
                }
            }

            .booking-right-image {
                margin-top: 10px;
                height: 154px;
                width: 100%;
                margin: 0;
                padding: 0;
                outline: none;
                border: none;
                box-sizing: border-box;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }

            a {
                font-size: inherit;
                text-align: center;
                margin-bottom: 20px;
            }

            hr {
                border-style: solid;
                color: $light-gray;
            }

            .booking-right-content {

                a {
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #424547;
                }

                table {
                    width: 100%;

                    tr {
                        td {
                            line-height: 1.4;

                            &.text-top {
                                vertical-align: top;
                            }

                            &.text-bold {
                                font-weight: 500;
                            }

                            &:nth-child(1) {
                                color: $gray;
                            }

                            &:nth-child(2) {
                                text-align: right;
                                padding-bottom: 8px;
                            }

                            &.booking-remove-padding {
                                padding-bottom: 0;
                            }
                        }
                    }

                    .booking-right-price {
                        @extend .green-text;

                        text-align: right;
                        font-size: 30px;
                        font-weight: 500;
                    }
                }

                &.tallinn-riga {
                    a, table .booking-right-price {
                        color: $blue;
                    }
                }
            }
        }

        .booking-terms {
            text-align: center;
            font-size: 14px;
            @include ProximaNova;

            a {
                @include ProximaNova;
                font-weight: bold;
                margin-top: 4px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0px;
            }

            input[type="checkbox"]:before{
                position: relative;
                display: block;
                width: 16px;
                height: 16px;
                border: 1px solid #8C8C8C;
                content: "";
                background: #FFF;
                margin-left: -1px;
                margin-top: -1px;
            }

            input[type="checkbox"]:after{
                position: relative;
                display: block;
                left: 1px;
                top: -17px;
                width: 16px;
                height: 16px;
                content: "";
                background-repeat: no-repeat;
                background-position:center;
            }

            input{
                height:18px;
                width: 18px;
                cursor: pointer;    
            }

            input[type="checkbox"]:checked:after{
              background-image: url("/static/img/icon_checked.svg");
            }

            label {
              margin-left: 12px;
            }

            #terms {
                margin-bottom: 5px;
            }

            #policy {
                margin-bottom: 5px;
            }
        }
    }
}
.review_qa_section {
    background: #fff;
    padding-top: 16px;
    padding-bottom: 16px;


    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "review qa";
        column-gap: 32px;


        @media (max-width: $tablet) {
            //grid-template-columns: 1fr;
            //grid-template-rows: 1fr 1fr;
            //grid-template-areas: "review" "qa";
            display: block;
        }

        .review-area {
            grid-area: review;

            .reviews {
                padding: 0;

                .review {
                    border: 0;
                    p {
                        @include ProximaNova;
                    }
                }

                .review.aggregate {
                    @media (max-width: $tablet) {
                        margin: auto;
                        text-align: center;
                    }

                    line-height: 32px;

                    .stars {
                        display: inline-block;
                        vertical-align: text-bottom;
                        .star {
                            height: 30px;
                            width: 30px;
                        }
                    }

                    .avg-rating {
                        margin-left: .2em;
                        font-size: 30px;
                        font-weight: bold;
                    }
                }


                .load_more_button_container {
                    @media (max-width: $tablet) {
                        text-align: center;
                        margin-bottom: 48px;
                    }

                }
            }
        }

        .qa-area {
            @media (max-width: $tablet) {
                padding-left: 70px;
                padding-right: 70px;
            }
            @media (max-width: $mobile) {
                padding-left: 22px;
                padding-right: 22px;
            }
            grid-area: qa;
            
            grid-area: qa;

            .questions_section {
                padding: 0;
                p {
                    @include ProximaNova;
                }
            }


        }
    }
}

.return-to-booking-container{
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 32px;
    text-align: center;
    .return-to-booking.button {
        color: white;
        background-color: #2bb656
    }
}

.iti-flag {
    background-image: url("/static/img/flags.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .iti-flag {
        background-image: url("/static/img/flags@2x.png");
    }
}
.secure-payments-info {
    background: none !important;
    h4 {
        margin-bottom: 6px;

        span {
            vertical-align: top;
            margin-left: 4px;
        }
    }

    .payment-methods {
        img {
            background: white;
            padding-left: 4px;
            padding-right: 4px;
            margin-left: 4px;
            margin-right: 4px;
            border-radius: 4px;
            border: solid 1px #ccc;
        }

        img:first-child {
            margin-left: 0;
        }
    }

    p {
        font-family: "ProximaNova", sans-serif;
        font-weight: 400;
        margin-top: 4px;
        margin-bottom: 4px;
    }
}

#reviews-modal {
    max-width: 851px;
    width: 100%;
    background-color: #fff;
    margin-top: 15px;
    cursor: auto !important;
    z-index: 0;

    img.close {
        margin-right: 20px;
        margin-top: 34px;
        float: right;
        cursor: pointer;
    }

    .reviews {
        padding: 10px;
        padding-left: 16px;
        padding-right: 16px;

        .review-list {
            .review {
                p {
                    @include ProximaNova;
                }
                @media (max-width: $tablet) {
                    padding-left: 22px;
                    padding-right: 22px;
                }
                
                .stars {
                    margin-bottom: 8px;
                }
            }

            .review:last-of-type {
                border-bottom: none;
            }
        }

        .review.aggregate {
            display: none !important;

        }
    }

    #load_review_modal {
        display: none;
    }


    .review-modal-close {
      background: rgba(255, 255, 255, .85);
      background: linear-gradient(0deg, rgba(255,255,255,0.9150253851540616) 75%, rgba(255,255,255,0.04667804621848737) 100%);
      position: sticky;
      padding: 2em;
      bottom: 0;
      text-align: center;
      cursor: pointer;
    }
}

#terms-modal {
    max-width: 851px;
    width: 100%;
    background-color: #fff;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -30px;
    position: relative;

    .flatpage-content {
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.13);

        img {
            margin-left: 90%;
            margin-top: 3.5%;
            position: absolute;
        }

        .decorated {
            padding-top: 15px;
            margin-top: 20px;
            margin-bottom: 30px;
            font-weight: 500;
            font-size: 16px;
            border: 0px;
            padding-left: 0px;
            padding-right: 0px;
            color: #2bb656;
            text-align: center;
            text-transform: uppercase;
            line-height: 20px;
            letter-spacing: 2px;
        }

        .flatpage-text {
            color: #8c8c8c;
            padding-left: 22px;
            padding-right: 22px;
            padding-bottom: 20px;
            margin-right: auto;
            margin-left: auto;
            line-height: 1.2;
            max-width: 688px;
            font-family: "ProximaNova", sans-serif;

            p {
                margin-top: 0;
            }
        }
    }
}

@media (max-width: $mobile + 1) {
    #terms-modal {
        margin-top: -20px;
    }
}

@media (min-width: $mobile + 1) {
    #terms-modal {
        .flatpage-content {
            img {
                margin-left: 95%;
                margin-top: 2.5%;
                position: absolute;
            }
        }
    }
}
//  and (max-width: $tablet)
