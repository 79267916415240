section.schedules {
    background-color: $light-gray;
    padding: 90px 0 40px;
    min-height: 100%;
    box-sizing: border-box;
    color: black;

    .content {
        width: 900px;
    }

    .full-screen-modal {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .button {
        background-color: $green;
        border-radius: 25px;
        color: white;
        line-height: 2.5;
        cursor: pointer;

        &.red {
            background-color: rgba(210, 0, 0, 1);
            border-color: rgba(210, 0, 0, 1);
        }
    }

    #today-button, #my-tours, #without-guide, #datepicker {
        margin-right: 10px;
        margin-top: 15px;

    }

    #datepicker {
        border: 0px;
    }

    #details-content {
        background-color: $light-gray;
        overflow:   scroll;
        padding: 0px;

        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }
    }

    .details-modal-full-screen {
      width: 100%;
      height: 100%;
      margin: 0;
      top: 0;
      left: 0;
    }

    .close-modal {
      display: flex;
      justify-content: center;
    }

    .close-button {
      background-color: $green;
      color: white;
      border: 0;
      line-height: 2.5;
      cursor: pointer;
      margin-top: 40px;
      font-family: "Rubik", sans-serif;
      font-size: 25px;
      padding: 0 50px;
      border-radius: 32px;
    }

    #last-week {
        margin-top: 15px;

        border: 0px;
        background-color: white;
        background-image: url("/static/img/icon_previous.svg");
        background-repeat: no-repeat;
        background-position: 20px;
        height: 38px;
        padding-left: 43px;
        color: #2bb656;
        font-size: 14px;
        @include Rubik-Medium;
        letter-spacing: 0.5px;
        float: right;
    }

    #next-week {
        display: block;
        margin-top: 30px;
        border: 0px;
        min-width: 230px;
        background-color: white;
        background-image: url("/static/img/icon_next.svg");
        background-repeat: no-repeat;
        background-position: 20px;
        background-position: 58px;
        padding-left: 33px;
        padding-top: 1px;
        height: 48px;
        color: #2bb656;
        font-size: 14px;
        @include Rubik-Medium;
        letter-spacing: 0.5px;
        margin-left: auto;
        margin-right: auto;
    }

    table#schedule-table {
        width: 100%;
        border-spacing: 3px;
        margin-top: 20px;

        thead {
            tr {
                height: 50px;
                font-size: 18px;
            }
        }

        td {
            background-color: white;
            padding: 5px;
            vertical-align: top;
            width: calc(100% / 7);
            border-radius: 2px;
            box-shadow: 1px 1px 4px #CCC;

            a {
                color: inherit;
                text-decoration: none;
            }
        }

        .schedule-cell {
            &-header {
                padding: 5px;
                font-weight: 600;

                &-left, &-right {
                    display: inline-block;
                    vertical-align: middle;
                }

                &-left {
                    float: left;
                }

                &-right {
                    float: right;
                }
            }

            &-content {
                .schedule-item {
                    margin-bottom: 5px;
                    padding: 5px;
                    border-radius: 4px;
                    box-sizing: border-box;
                    font-size: 12px;
                    border-radius: 2px;
                    box-shadow: 1px 1px 2px #999;

                    .schedule-warning-icon {
                        background-image: url(/static/traveller/images/icon_warning.png);
                        height: 16px;
                        width: 13px;
                        display: inline-block;
                        vertical-align: text-bottom;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    &-guide {
                        font-weight: 600;
                        text-align: right;
                        font-style: italic;

                        span.logged-guide {
                            background-color: white;
                            padding: 2px;
                            border-radius: 5px;
                        }

                        span.guide-assistant {
                            font-weight: normal;
                        }
                    }
                }
                .schedule-item.recommend-delete {
                    text-decoration: line-through;
                    opacity: 0.6;
                }
            }
        }
    }

    .schedule-details {
        &-header {
            padding: 0 30px;
            line-height: 1.5;

            a {
                color: $green;
                font-weight: 600;
                text-decoration: none;
            }
        }

        &-table {
            width: 100%;
            border-spacing: 0;
            margin-top: 40px;
            border: 1px solid #ddd;

            a {
                color: inherit;
                text-decoration: none;
            }

            .booking-status-wrapper {
                .ui-selectmenu-button.ui-button {
                    border-radius: 30px;
                    width: auto;

                    .ui-selectmenu-icon {
                        display: none;
                    }

                    .ui-selectmenu-text {
                        margin: 0;
                    }

                    &.pending {
                        border-color: orange;
                        color: orange;
                    }

                    &.success {
                        border-color: $green;
                        color: $green;
                    }

                    &.cancelled {
                        border-color: red;
                        color: red;
                    }
                }
            }

            thead {
                tr {
                    background: #f8f8f8;
                }

                th {
                    text-align: left;
                    padding: 10px;
                }
            }

            tbody {
                tr {
                    &.schedule-details-table__even {
                        background-color: #f8f8f8;
                    }

                    &.schedule-details-table__odd {
                        background-color: white;
                    }
                }

                td {
                    padding: 10px;
                    border-top: 1px solid #CCC;

                    &.schedule-details-table__paid {
                        background-image: url("/static/img/icon_paid.svg");
                        background-repeat: no-repeat;
                        background-position: 40px;
                    }

                    &.schedule-details-table__unpaid {
                        background-image: url("/static/img/icon_unpaid.svg");
                        background-repeat: no-repeat;
                        background-position: 40px;
                    }
                }
            }
        }
    }

    .schedules-dates {
        text-align: center;
        margin: 20px 0;

        &-input {
            display: inline-block;

            label {
                font-weight: 600;
                margin-right: 5px;
            }

            input[type="text"] {
                padding: 10px;
                margin-right: 10px;
            }
        }

        &-guides {
            width: 100%;
            border: 1px solid #CCC;
            margin-top: 40px;
            border-spacing: 0;

            thead {
                tr {
                    background-color: #f8f8f8;
                }

                th {
                    text-align: left;
                    padding: 10px;
                }
            }

            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: white;
                    }

                    &:nth-child(even) {
                        background-color: #f8f8f8;
                    }
                }

                td {
                    padding: 10px;
                    border-top: 1px solid #CCC;
                }
            }
        }
    }

    input[name="date"] {
        font-weight: 600;
        border-radius: 20px 20px;
        width: calc(100%/5);
        margin: 0px;
        padding: 9px;
        font-family: "ProximaNova", sans-serif;
        box-sizing: border-box;
        height: 100%;
        text-align: center;
        font-size: 15px;
        background-repeat: no-repeat;
        background-image: url("/static/img/icon_calendar.svg"),
            url("/static/img/icon_arrow_header_gray.svg");
        background-position: center left 15px, center right 15px;
        border: 1px solid #cccccc;
        cursor: pointer;
        color: #404040;
    }

    .lds-default {
        display: none;
        position: relative;
    }

    .lds-top {
        float: right;
        width: 48px;
        height: 48px;
        margin-right: 20px;
    }

    .lds-bot {
        margin-top: 20px;
        float: center;
        margin-left: auto;
        margin-right: auto;
        width: 58px;
        height: 58px;
        padding-right: 20px;
    }

    .lds-default div {
        position: absolute;
        width: 5px;
        height: 5px;
        background: #2bb656;
        border-radius: 50%;
        animation: lds-default 1.2s linear infinite;
    }

    .lds-default div:nth-child(1) {
        animation-delay: 0s;
        top: 29px;
        left: 53px;
    }

    .lds-default div:nth-child(2) {
        animation-delay: -0.1s;
        top: 18px;
        left: 50px;
    }

    .lds-default div:nth-child(3) {
        animation-delay: -0.2s;
        top: 9px;
        left: 41px;
    }

    .lds-default div:nth-child(4) {
        animation-delay: -0.3s;
        top: 6px;
        left: 29px;
    }

    .lds-default div:nth-child(5) {
        animation-delay: -0.4s;
        top: 9px;
        left: 18px;
    }

    .lds-default div:nth-child(6) {
        animation-delay: -0.5s;
        top: 18px;
        left: 9px;
    }

    .lds-default div:nth-child(7) {
        animation-delay: -0.6s;
        top: 29px;
        left: 6px;
    }

    .lds-default div:nth-child(8) {
        animation-delay: -0.7s;
        top: 41px;
        left: 9px;
    }

    .lds-default div:nth-child(9) {
        animation-delay: -0.8s;
        top: 50px;
        left: 18px;
    }

    .lds-default div:nth-child(10) {
        animation-delay: -0.9s;
        top: 53px;
        left: 29px;
    }

    .lds-default div:nth-child(11) {
        animation-delay: -1s;
        top: 50px;
        left: 41px;
    }

    .lds-default div:nth-child(12) {
        animation-delay: -1.1s;
        top: 41px;
        left: 50px;
    }

    @keyframes lds-default {
      0%, 20%, 80%, 100% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.5);
      }
    }
}
