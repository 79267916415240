.ui-corner-all {
    border-radius: 0px;
}

.ui-corner-top {
    border-radius: 0px;
}

.ui-button, .ui-button:hover, .ui-button:focus {
    padding: 9px 20px;
}

.ui-widget, .ui-widget:hover, .ui-widget:focus {
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    background: #ffffff;
}

.ui-button .ui-icon-triangle-1-s, .ui-button:hover .ui-icon-triangle-1-s, .ui-button:focus .ui-icon-triangle-1-s {
    background: url("/static/img/icon_arrow_expand_green.svg") no-repeat center center;
}

/*
.ui-selectmenu-button.ui-button {
    display: inline-block;
    width: auto;
}

*/

.ui-datepicker {
    width: 360px;
    min-height: 334px;
    padding: 30px 50px;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);

    @media (max-width: $mobile) {
        width: 256px;
        min-height: 0;
        padding: 2px;
    }
}

.ui-datepicker.ui-corner-all {
    border-radius: 4px;
}

.ui-datepicker .ui-datepicker-header {
    background: transparent;
    border: 0px;
    margin-bottom: 20px;

    @media (max-width: $tablet) {
        margin-bottom: 0;
    }
}

.ui-datepicker .ui-datepicker-title {
    color: #404040;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 24px;
}

.ui-datepicker .ui-state-default {
    border: 0px;
    background: transparent;
    color: $green;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
}

.ui-datepicker .ui-state-disabled .ui-state-default {
    color: #8a8a8a;
    font-weight: 500;
    opacity: 1;
}

.ui-datepicker .ui-state-active {
    color: #ffffff;
    background: $green;
    border-radius: 50%;
}

.ui-datepicker .ui-state-hover {
    text-decoration: underline;
}

.ui-datepicker th {
    text-align: center;
}

.ui-datepicker .ui-icon-circle-triangle-w {
    background: none;
}

.ui-datepicker .ui-icon-circle-triangle-e {
    background: none;
}

.ui-datepicker .ui-datepicker-prev:after {
    content: "<";
}

.ui-datepicker .ui-datepicker-next:after {
    content: ">";
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
    text-align: center;
}

.ui-datepicker .ui-datepicker-prev:after, .ui-datepicker .ui-datepicker-next:after {
    color: $green;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 24px;
}

.ui-datepicker .ui-datepicker-prev-hover.ui-state-hover, .ui-datepicker .ui-datepicker-next-hover.ui-state-hover {
    background: none;
    border-color: #ffffff;
}

.ui-datepicker * {
    font-family: 'Rubik';
}

.ui-selectmenu-text {
    text-align: center;
}

.ui-widget.ui-widget-content {
    // max-height: 200px;
}

@media (max-width: $mobile) {
    body #ui-datepicker-div {
        position: fixed !important;
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        padding: 0;
        width: 100vw;
        box-sizing: border-box;
        z-index: 10 !important;
        border-radius: 0;
    }

    body #ui-datepicker-div .datepicker-footer {
        padding: 10px;
        max-width: 284px;
        margin: auto;
        box-sizing: border-box;
    }

    body #ui-datepicker-div .datepicker-legend {
        margin-bottom: 10px;
    }

    body #ui-datepicker-div .datepicker-legend .datepicker-color-block {
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }

    body #ui-datepicker-div .datepicker-header {
        padding: 12px 20px;
        position: relative;
        margin: auto;
        border-bottom: 1px solid #e2e2e2;
    }

    body #ui-datepicker-div .datepicker-header h2 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-family: 'Rubik';
        font-weight: 500;
        text-transform: uppercase;
        color: $green;
    }

    body #ui-datepicker-div .datepicker-header .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 70px;
        background-image: url("/static/img/icon_close.svg");
        background-position: center center;
        background-size: 15px;
        cursor: pointer;
        background-repeat: no-repeat;
    }

    body #ui-datepicker-div .ui-datepicker-header {
        max-width: 284px;
        margin: 20px auto;
    }

    body #ui-datepicker-div .ui-datepicker-calendar {
        max-width: 284px;
        margin: auto;
        border-spacing: 3px;
        border-collapse: separate;
    }

    body #ui-datepicker-div .ui-datepicker-calendar td {
        width: 38px;
        height: 38px;
        box-sizing: border-box;
        text-align: center;
    }

    body #ui-datepicker-div .ui-state-default {
        background-color: transparent;
        border: none;
        text-align: center;
        padding: 0;
        margin: 0;
    }

    body #ui-datepicker-div .ui-state-active {
        border-radius: 0;
        color: #2bb656 !important;
    }

    body #ui-datepicker-div .ui-datepicker-current-day {
        box-shadow: inset 0 0 0 1px #2bb656;
        background: #fff !important;
    }

}
