.tour-section {
  color: #484848;

  p,
  ul,
  p.large {
    font-size: 17px;
    line-height: 26px;
    list-style: outside;
  }

  .tour-details-button {
    margin: 20px 0;
    text-align: center;

    a {
      @include Rubik-Medium;
      background-color: rgba(255, 255, 255, 0);
      text-decoration: none;
      font-size: 14px;
      letter-spacing: 1px;
    }
  }

  .booking-form {
    .discount_ends_date {
      @include ProximaNova;
      font-size: 14px;
      position: relative;
      padding-left: 19px;
      margin: 0 30px 20px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: calc(50% - 8px);
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-image: url("/static/img/warning.svg");
        background-position: center center;
        background-size: 14px;
      }
    }

    .warning-text {
      text-align: center;
      margin-top: 10px;
      color: $orange;
    }
    &-ribbon {
      $orange: #ffa823;
      @include ProximaNova;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      display: inline-block;
      background-color: $orange;
      color: white;
      padding: 8px 12px;
      font-size: 14px;
      box-sizing: border-box;
      text-align: center;
      .bold {
        font-weight: 600;
      }
    }
    &-header {
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 0 30px;

      &-input {
        margin: 5px 0;
        input[name="date"] {
          @include Rubik-Medium;
          background-image: url("/static/img/icon_calendar.svg");
          background-repeat: no-repeat;
          background-position: center left 25px;
          background-color: #fff;
          display: inline-block;
          border: 1px solid #cccccc;
          width: 100%;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          text-align: center;
          padding-right: 20px;
          padding-top: 14px;
          padding-bottom: 14px;
          outline: 0;
          line-height: 18px;
          position: relative;
          font-size: 16px;
          color: #404040;
          cursor: pointer;
          padding-right: 0;
          padding-left: 60px;
          text-align: left;
        }
        .people-button {
          @include Rubik-Medium;
          background-position: center left 25px, center right 25px;
          padding-right: 0;
          padding-left: 60px;
          text-align: left;
        }
      }
    }
   .loader {
      position: absolute;
      text-align: center;
      padding-top: 5.0em;
      font-weight: bold;
      z-index: 500;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(255,255,255,.99);
    }



    &-main {
      margin: 0 5px;
      margin-top: 20px;
      @include Rubik;
      &-item {
        background-color: white;
        padding: 20px 30px;
        position: relative;
        &#private-tour {
          padding-top: 0;
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &-header {
          @include Rubik-Medium;
          font-size: 20px;
          letter-spacing: 0.2px;
          line-height: 1;
          color: $green;
          margin-bottom: 10px;

          &.ribbon {
            padding-top: 50px;
          }
        }
        &-description {
          font-size: 16px;
          line-height: 1.2;
        }
        &-select {
          margin-top: 20px;
          > * {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
          label {
            font-size: 14px;
            min-width: 120px;
          }
          input,
          span[role="combobox"] {
            @include Rubik;
            border: 1px solid $green;
            font-size: 14px;
            letter-spacing: 1px;
            text-decoration: none;
            color: $green;
            font-weight: 500;
            border-radius: 40px;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            text-transform: uppercase;
            user-select: none;
            width: 100px;
            background-color: white;
            height: 100%;
            padding: 9px 15px;
            -webkit-text-fill-color: $green;
            opacity: 1;
            -webkit-opacity: 1;

            &[disabled] {
              line-height: 0;
            }
          }
        }

        &-confirm {
          button {
            width: 100%;
            font-size: 16px;
          }
        }

        &-price {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          margin: 10px 0 20px;
          .small-text {
            font-size: 14px;
          }
          .main-text {
            @include Rubik-Medium;
            font-size: 30px;
            color: $green;
            line-height: 1;
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            &-discount {
              @include Rubik;
              font-size: 14px;
              color: #404040;
              margin-right: 5px;
              text-decoration: line-through;
            }
            &.discount {
              color: $orange;
            }
          }
        }

        hr {
          padding: 0;
          border: 0;
          border-top: 1px solid #f2f2f2;
          margin: 20px 0;
        }

        &-footer {
          @include Rubik;
          display: flex;
          flex-direction: column;
          font-size: 14px;
          justify-content: flex-end;
          &-price-breakdown {
            display: flex;
            justify-content: space-between;
            .price-breakdown {
              &-details {
                text-align: right;
              }
            }
          }
        }

        &.unavailable {
          font-size: 14px;
          font-family: "Rubik", sans-serif;
          font-weight: 400;
          line-height: 1.6;
          .booking-form-main-item-header {
            color: $gray;
          }
        }

        .next_available_date {
          color: $green;
          display: inline-block;

          a {
            text-decoration: none;
            margin-right: 10px;
            color: inherit;
          }
        }
      }
    }
  }

  &.tour-section-images {
    $height: 240px;
    padding: 0;
    height: $height;
    overflow: hidden;
    position: relative;

    .tour-images {
      height: $height;
      .tour-image {
        height: $height;
        img {
          height: $height;
          object-fit: cover;
          width: 100%;
        }
      }
      &-left-arrow,
      &-right-arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 50px;
        z-index: 1;
        background-position: center center;
        background-size: 50%;
        background-repeat: no-repeat;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
      &-left-arrow {
        left: 0;
        background-image: url("/static/img/gallery_icon_arrow_left_white.svg");
      }

      &-right-arrow {
        right: 0;
        background-image: url("/static/img/gallery_icon_arrow_right_white.svg");
      }
    }

    .tour-gallery-button {
      position: absolute;
      bottom: 10px;
      right: 10px;
      color: #434547;
      font-size: 16px;
      font-family: "Rubik";
      font-weight: 500;
      background-color: #fff;
      padding: 9px 11px;
      border-radius: 100px;
      box-shadow: 0 2px 4px #000;

      > * {
        display: inline-block;
      }

      &-icon {
        width: 20px;
        height: 12px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        &-arrow {
          background-image: url(/static/mobile/img/icon_arrow.svg);
        }

        &-gallery {
          background-image: url(/static/mobile/img/icon_gallery.svg);
        }
      }

      &-count-label {
        margin: 0 5px;
      }
    }
  }

  .margin-before-instagram-gallery {
    font-size: 15px;
    line-height: inherit;
    margin: 10px 0px;
  }

  .tour-description-padding {
    @media (min-width: $mobile + 1) and (max-width: $tablet) {
      padding: 0 70px !important;
    }
  }
}

.tour_header {
  padding: 30px 0px 0px;
  text-align: center;

  * {
    font-family: "Rubik", sans-serif;
  }

  @media (min-width: $mobile + 1) and (max-width: $tablet) {
  }

  @media (max-width: $mobile) {
    text-align: left;
    padding-top: 20px;
  }

  h1 {
    margin: 0px 0px 10px;
    line-height: 1.2;

    @media (min-width: $mobile + 1) and (max-width: $tablet) {
      font-size: 30px;
      line-height: 34px;
      text-align: center;
    }

    @media (max-width: $mobile) {
      font-size: 20px;
      text-align: left;
      margin-bottom: 16px;
    }
  }

  .tour-details {
    margin: 0;
    padding: 0;
    font-size: 19px;
    line-height: 23px;
    position: relative;

    @media (max-width: $mobile) {
      margin: 16px 0px;
      font-size: 15px;
    }

    > div {
      display: inline-block;
      vertical-align: middle;
      margin: 0px 20px 35px;

      @media (min-width: $mobile + 1) and (max-width: $tablet) {
        margin-bottom: 10px;
      }

      @media (max-width: $mobile) {
        display: block;
        margin: 10px 0px;
      }

      .stars {
        display: inline-block;
        vertical-align: bottom;

        @media (max-width: $mobile) {
          vertical-align: baseline;
        }

        .star {
          width: 23px;
          height: 23px;
          background: url("/static/img/icon_star_small.svg") no-repeat center;
          background-size: 100%;
          float: left;

          @media (max-width: $mobile) {
            width: 17px;
            height: 17px;
          }
        }
      }

      .rating {
        margin-left: 10px;
        display: inline-block;
      }

      .duration {
        padding-left: 29px;
        background: url(/static/img/icon_mobile_duration_tour-detail_1st_section.svg) no-repeat;
        background-position: 5px center;

        @media (max-width: $mobile) {
          background-size: 16px;
        }
      }

      .group-size {
        padding-left: 30px;
        background: url(/static/img/icon_mobile_people_1st_section.svg) no-repeat left center;

        @media (max-width: $mobile) {
          background-size: 25px;
        }
      }

      .price {
        font-size: 42px;
        font-weight: 500;
        line-height: 42px;
        letter-spacing: 0px;
        color: $green;
        &.discount {
          color: $orange;
        }
      }

      .price-container {
        .price-discount {
          text-decoration: line-through;
          font-size: 15px;
          margin-right: 5px;
        }
        @media (max-width: $mobile) {
          position: absolute;
          right: 0px;
          bottom: 16px;
        }
      }
    }
  }

  ul.tour_details {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    font-size: 19px;
    line-height: 23px;
    position: relative;

    @media (max-width: $tablet) {
      margin: 16px 0px;
      font-size: 15px;
    }

    > li {
      display: inline-block;
      vertical-align: middle;
      margin: 0px 20px 35px;

      @media (max-width: $tablet) {
        display: block;
        margin: 14px 0px;
      }

      .stars {
        .star {
          width: 20px;
          height: 19px;
          background: url("/static/img/icon_star_small.svg") no-repeat center;
          background-size: 100%;
          float: left;

          @media (max-width: $tablet) {
            width: 11px;
            height: 11px;
          }
        }
      }

      .rating {
        margin-left: 10px;
      }

      .duration {
        padding-left: 29px;
        background: url(/static/img/icon_mobile_duration_tour-detail_1st_section.svg) no-repeat;
        background-position: 3px 1px;

        @media (max-width: $tablet) {
          background-size: 16px;
        }
      }

      .group_size {
        padding-left: 36px;
        background: url(/static/img/icon_mobile_people_1st_section.svg) no-repeat left center;

        @media (max-width: $tablet) {
          background-size: 25px;
        }
      }

      .price {
        font-size: 42px;
        font-weight: 500;
        line-height: 42px;
        letter-spacing: 0px;
        color: $green;
      }

      .price_container {
        @media (max-width: $tablet) {
          position: absolute;
          right: 0px;
          bottom: 16px;
        }
      }
    }
  }
}

.video_container {
  position: relative;
  width: 748px;
  height: 421px;
  margin-bottom: 10px;
  background-size: cover;

  @media (max-width: $tablet) {
    width: 100%;
    height: 56.25vw;
  }

  img {
    width: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .play {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: url(/static/traveller/images/video-play.png) no-repeat center;
    cursor: pointer;
  }
}

.fixed-availability {
  @include Rubik-Medium;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  align-items: center;
  background-color: #fff;
  font-size: 16px;
  z-index: 1;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.25);
  .price {
    &-header {
      color: #8c8c8c;
      font-size: 12px;
      @include ProximaNova;
    }
    &-footer {
      color: #424547;
      font-size: 30px;
      &.discount {
        color: $orange;
      }
      span {
        font-size: 20px;
      }
    }
  }
  .availability-button {
    .button {
      height: 44px;
      font-size: 16px;
      word-spacing: 1px;
    }
  }
}

.tour_gallery {
  /*margin: 40px 0px;*/

  .tour_gallery_image {
    display: block;
    width: 142px;
    height: 142px;
    margin-left: 9px;
    margin-bottom: 10px;
    float: left;
    position: relative;
    background-size: cover;
    background-position: center;
    cursor: pointer;

    &:first-child {
      width: 294px;
      height: 292px;
      margin-left: 0px;
    }

    &:nth-child(7) {
      border-radius: 10px;

      &:after {
        content: "View Gallery";
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(43, 182, 86, 0.93);
        background-image: url(/static/img/icon_arrow_right_white.svg);
        background-repeat: no-repeat;
        background-position: center 90px;
        text-transform: uppercase;
        box-sizing: border-box;
        padding: 50px 20px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        font-family: "Rubik", sans-serif;
        letter-spacing: 1px;
        line-height: 17px;
        text-align: center;
      }
    }

    &:nth-child(n + 8) {
      display: none;
    }

    @media (max-width: $tablet) {
      width: 33.33%;
      height: 33.33vw;
      margin: 0px;

      &:first-child {
        display: none;
      }
    }
  }

  @media (max-width: $tablet) {
    margin-bottom: -15px;

    /* TODO: This is a little hackish */
  }
}

.section-tour-description {
  font-size: 17px;
  line-height: 26px;
  list-style: outside;
  position: relative;
  overflow: auto;
  margin-bottom: -10px;

  h2 {
    margin: 30px 0px 20px;
    &:first-child {
      margin: 16px 0px;
    }
  }

  &.fixed {
    height: 200px;
    overflow: hidden;
  }

  .gradient-bg {
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    background-position: top;
    background-size: 100% 65%;
    background-repeat: no-repeat;
  }

  iframe {
    width: 100%;
    height: 380px;
  }

  a {
    @include ProximaNova;
    font-weight: bold;
    color: $green;
    text-decoration: none;
    font-size: 17px;
    cursor: pointer;
  }
}

.pricing-table {
  display: none;
  margin: 20px 0px 20px;
  border: 1px solid #F2F2F2;
  border-collapse: collapse;
  width: 100%;
  text-align: center;

  tr {
    border: 1px solid #F2F2F2;
    height: 32px;

    &:first-child {
      background-color: #FFFFFF !important;
    }

    &:nth-child(odd) {
      background-color: #FBFBFB;
    }
  }

  th {
    padding: 10px;
    border: 1px solid #F2F2F2;
  }

  td {
    width: 50%;
    color: #404040;
    font-size: 14px;
    font-family: "Rubik-Medium", sans-serif;
    border: 1px solid #F2F2F2;

  }

  .table-title {
    color: #404040;
    font-size: 14px;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    width: 110px;
  }
}

.highlights-section {
  margin: 0;
  position: relative;
  padding-left: 25px;
  line-height: 22px;

  img {
    position: absolute;
    left: 0;
  }

  img.high {
    top: 1px;
  }

  img.inc {
    top: 4px;
  }

  img.add {
    top: 6px;
  }

  span {
    color: #424547;
    font-size: 17px;
    font-family: "ProximaNova", sans-serif;
    line-height: 22px;
  }
}

.about-the-tour-table {
  text-align: center;
  margin-bottom: 20px;

  td {
    padding: 5px;
    font-size: 17px;
    color: #424547;
    font-family: "ProximaNova", sans-serif;

    &:nth-child(even) {
      text-align: left;
    }

    img {
      vertical-align: text-top;
    }
  }

  a {
    text-decoration: none;
    color: $green;
    font-size: 17px;
    font-family: "ProximaNova", sans-serif;
  }
}

#included, #additional {
  margin: 30px 0px 20px;
}

#img-included, #img-additional {
  margin-left: 3px;
  position: relative;
  top: 0;
  right: 0;
}

.included,
.additional {
  display: none;
}

.booking-additional-info {
  @include ProximaNova;
  color: #424547;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  padding: 0px 15px;
  margin: 20px 0px 20px;
}


.private-pricing {

  display: none;
  margin: 0 30px;

  .pricing-dropdown-content {
      display: none;
      margin-top: 0;
  }
  p {
      text-align: center;
      line-height: 1.2;
      margin-top: 30px;
      margin-bottom: 0;
      font-size: 14px;
      @include Rubik;
  }
  .pricing-dropdown-expand {
      padding-top: 3px;
      padding-bottom: 20px;
      text-align: center;
      cursor: pointer;
      background-image: url("/static/img/icon_small_expand_desktop.svg");
      background-position: 90.5% 23%;
      background-repeat: no-repeat;
      font-size: 14px;
      font-family: "Rubik-Medium", sans-serif;

      b {
        font-family: "Rubik", sans-serif;
        font-weight: 500;
    }
  }
  .pricing-dropdown-content {
    .dropdown-pricing-table {
      border-collapse: collapse;
      width: 100%;
    }
  }
  .dropdown-price-per-person-title {
    border-left: 0.25px solid #f2f2f2;
    border-bottom: 0.25px solid rgb(242, 242, 242);
  }

  .dropdown-header-title {
    border-bottom: 0.25px solid rgb(242, 242, 242);
  }
  .dropdown-header-title, .dropdown-group-size-title, .dropdown-price-per-person-title {
      text-align: center;
      height: 30px;
      color: #424547;
      background-color: #FFF;
      line-height: 30px;
      font-size: 14px;
      @include Rubik-Medium;
  }
  .dropdown-group-size-title, .dropdown-price-per-person-title {
      box-sizing: border-box;
      width: 50%;
      position: relative;
  }
  .dropdown-price-per-person-value {
    border-left: 0.25px solid #f2f2f2;
  }
  .dropdown-group-size-value, .dropdown-price-per-person-value {
      background-color: #FFFFFF;
      color: #424547;
      height: 30px;
      line-height: 30px;
      border-top: 0.25px solid #f2f2f2;
      border-bottom: 0.25px solid #f2f2f2;
      text-align: center;
      font-size: 14px;
      @include Rubik;
  }
  .dropdown-group-size:nth-child(odd) {
    td {
      background-color: #FBFBFB;
    }
  }
}
