.flatpage-section {
    font-size: 15px;
    .decorated {
        margin: 80px 0 30px;
    }

    .flatpage-text {
        background-color: #fff;
        padding: 0;
        line-height: 17px;
        margin-bottom: 30px;
    }

    p {
        margin: 0;
        margin-bottom: 10px;
    }

    hr {
        margin: 10px 0;
        border: 1px solid #f2f2f2;
    }

    a {
        color: $green;
        text-decoration: none;
    }
}

.page-section {
    a {
        color: $green;
        text-decoration: none;
    }

    header {
        position: relative;
        display: table;
        width: 100%;
        height: 160px;
        text-align: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (min-width: 426px) {
            height: 240px;
        }

        .header-text {
            display: table-cell;
            vertical-align: middle;
            font-family: "Rubik", sans-serif;
            font-weight: 700;
            letter-spacing: 0.9px;
            color: #ffffff;
            text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.5);
            text-align: center;
            padding: 0 10px;
            font-size: 22px;
            line-height: 24px;
            padding-top: 20px;

            @media (min-width: 426px) {
                font-size: 30px;
                line-height: 34px;
            }

            .small {
                font-weight: 400;
            }
        }
    }

    .page-description {
        color: #484848;
        font-size: 17px;
        line-height: 21px;
        list-style: outside;
        margin: 20px 0px 0px;
        font-weight: 400;
        font-family: "ProximaNova";
        letter-spacing: 0px;

        @media (min-width: 426px) {
            padding: 0 70px;
            padding-bottom: 15px;
        }
    }

    .page-cta {
        ul.cta-block {
            list-style-type: none;
            padding: 0px;
            font-size: 0px;
            text-align: center;

            a {
                display: block;
            }

            li {
                position: relative;
                width: 90%;
                padding: 20px;
                box-sizing: border-box;
                margin-right: 11px;
                margin-bottom: 11px;
                background: #ffffff;
                white-space: normal;
                vertical-align: top;
                font-size: 16px;
                margin: 10px;
                display: inline-block;

                @media (min-width: 426px) {
                    width: 326px;
                    padding-bottom: 15px;
                }

                &:before {
                    content: "";
                }

                .title {
                    font-family: "Rubik", sans-serif;
                    font-size: 19px;
                    font-weight: 700;
                    letter-spacing: 0px;
                    line-height: 21px;
                    color: #424547;
                }

                .image {
                    display: block;
                    height: 178px;
                    background-image: url("/static/img/background_placeholder.jpg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    margin: -20px -20px 0px;
                }
            }
        }
    }

    .page-guides {
        @media (min-width: 426px) {
            padding: 0 70px;
            padding-bottom: 15px;
        }
        .guide-block {
            font-size: 15px;

            .guide-general {
                & > * {
                    display: inline-block;
                    vertical-align: middle;
                }
                .image {
                    width: 60px;
                    height: 60px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    border-radius: 50%;
                }
                .guide-text {
                    padding-left: 14px;
                    width: 200px;
                    box-sizing: border-box;
                    .guide-name {
                        color: #424547;
                        font-weight: 700;
                        font-family: "Rubik", sans-serif;
                        line-height: 21px;
                        letter-spacing: 0px;
                    }
                }
            }
            .guide-description {
                font-family: "ProximaNova";
                line-height: 1.1;
                color: #484848;
                margin: 10px 0;
            }

            border-bottom: 1px solid #f2f2f2;
            padding: 30px 0;
        }
    }
}
