.reviews-section {
    $black: #404040;
    $gray: #8c8c8c;
    $light-gray: #eee;

    background-color: #f2f2f2;
    padding-top: 1px;
    padding: 70px 0;
    font-family: "Rubik";
    font-size: 14px;
    line-height: 1.2;
    color: $black;

    &.full-screen {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    &-header,
    &-content {
        margin: auto;
        max-width: 494px;
    }

    &-header {
        // max-width: 1000px;

        &-text {
            margin-bottom: 40px;
            letter-spacing: 0.2px;

            p {
                font-family: "Rubik";
                font-size: 14px;
                line-height: 1.2;
            }

            a {
                color: $green;
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                letter-spacing: 1px;
                font-family: "Rubik";
            }
        }
    }

    &-content {
        max-width: 494px;
        padding: 0 18px;
    }

    &-form-element {
        margin: 0 0 20px;

        &-label {
            font-weight: 500;
        }

        &-field {
            margin-top: 10px;

            .star {
                width: 38px;
                height: 34px;
                display: inline-block;
                background-image: url(/static/img/icon_star_empty.svg);
                margin-right: 8px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                cursor: pointer;
            }

            input,
            textarea,
            select {
                width: 100%;
                margin: auto;
                border: 1px solid #d9d9d9;
                border-radius: 0;
                background-color: #fff;
                box-sizing: border-box;
                font-family: "Rubik";
                font-size: 14px;
                line-height: 1.2;
                color: $black;
                border-radius: 0;
                -webkit-appearance: none;
                -moz-appearance: none;

                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #8c8c8c;
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #8c8c8c;
                }

                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #8c8c8c;
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #8c8c8c;
                }
            }

            input,
            select {
                padding: 15px;
            }

            textarea {
                padding: 19px;
                line-height: 1.3;
            }

            select {
                cursor: pointer;
                background-image: url("/static/img/icon_arrow_header_gray.svg");
                background-position: center right 15px;
                background-repeat: no-repeat;
                &::-ms-expand {
                    display: none;
                }
            }

            button {
                width: 242px;
            }
        }

        &.size-6 {
            width: 242px;
            display: inline-block;

            &.left {
                margin-right: 5px;
            }

            &.right {
                margin-left: 5px;
            }
        }

        &.submit-button {
            text-align: center;
            margin: 20px 0 0;
        }

        &.has-error {
            .reviews-section-form-element-label {
                color: red;
            }

            .reviews-section-form-element-field {
                input,
                textarea {
                    border: 1px solid red !important;
                }
            }
        }
    }
}
