.category-page-section {
    .tour_list {
        text-align: center;

        .tour-discount-badge-wrapper {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            .tour-discount-badge {
                @include Rubik;
                color: #333;
                text-align: center;
                transform: rotate(45deg);
                position: relative;
                padding: 3px 0;
                right: -150px;
                top: 20px;
                width: 150px;
                margin: auto;
                background-color: $orange;
                color: #fff;
                font-size: 15px;
            }
        }

        li {
            display: inline-block;
            text-align: left;
            float: none;

            @media (min-width: $mobile + 1) and (max-width: $tablet) {
                width: 49%;
                margin: 3px;

                &:nth-child(3n + 3) {
                    margin-right: 3px;
                }
            }

            @media (max-width: $mobile) {
                display: block;
            }

            p {
                min-height: 57px;
            }
        }
    }
}
