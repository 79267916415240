.people-button {
    background-image: url("/static/img/icon_people.svg"),
        url("/static/img/icon_arrow_header_gray.svg");
    background-position: 25px center, right 15px center;
    background-repeat: no-repeat;
    background-color: #fff;

    /*padding-left: 71px;*/
    display: inline-block;
    border: 1px solid #cccccc;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    padding-right: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
    outline: 0;
    line-height: 18px;
    position: relative;
    font-size: 16px;
    color: #404040;
    cursor: pointer;

    strong {
        font-weight: 500;
    }
}

.people-button .inner-block {
    position: absolute;
    top: 0;

    /*top: 45px;*/
    background-color: white;
    left: 0;
    right: 0;
    display: none;
    border-radius: 4px;
    box-shadow: 1px 1px 7px #bbb;
    text-align: center;
    z-index: 1;
    cursor: default;

    .people-button-main {
        padding: 40px 30px;

        @media (max-width: $mobile) {
            padding: 30px;
        }
    }

    @media (min-width: $mobile + 1) {
        .people-button-header,
        .people-button-footer {
            display: none;
        }
    }

    @media (max-width: $mobile) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
    }

    .people-button-header {
        padding: 12px 20px;
        position: relative;
        margin: auto;
        border-bottom: 1px solid #e2e2e2;

        h2 {
            margin: 0;
            padding: 0;
            text-align: center;
            font-family: 'Rubik';
            font-weight: 500;
            text-transform: uppercase;
            color: $green;
        }

        .close-btn {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 70px;
            background-image: url(/static/img/icon_close.svg);
            background-position: center center;
            background-size: 15px;
            cursor: pointer;
            background-repeat: no-repeat;
        }
    }

    .people-button-footer {
        #show_availability {
            margin: 0 30px;
        }
    }
}

.people-button .inner-block .control-elements {
    position: relative;
    margin-bottom: 20px;
}

.people-button .inner-block .control-elements:last-child {
    margin-bottom: 0;
}

.people-button .inner-block .control-elements * {
    display: inline-block;
    padding: 13px 0;
    font-size: 19px;
    font-weight: 500;
    width: 47px;
    -webkit-touch-callout: none;

    /* iOS Safari */
    -webkit-user-select: none;

    /* Safari */
    -khtml-user-select: none;

    /* Konqueror HTML */
    -moz-user-select: none;

    /* Firefox */
    -ms-user-select: none;

    /* Internet Explorer/Edge */
    user-select: none;

    /* Non-prefixed version, currently
       supported by Chrome and Opera */
}

.people-button .inner-block .control-elements .contol-elements-button {
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid #dbdbdb;
}

.people-button .inner-block .control-elements .contol-elements-button:hover {
    background-color: #eee;
}

.people-button .inner-block .control-elements .contol-elements-button.contol-elements-left-button {
    border-radius: 20px 0 0 20px;
    color: #888;
    margin-right: -4px;
}

.people-button .inner-block .control-elements .contol-elements-button.contol-elements-right-button {
    border-radius: 0 20px 20px 0;
    color: $green;
    margin-left: -4px;
}

.people-button .inner-block .control-elements .control-number {
    border: 1px solid #dbdbdb;
    border-left: none;
    border-right: none;
}

.people-button .inner-block .label-text {
    /*margin: 10px 0;*/
    margin-bottom: 10px;
}

.ui-datepicker-trigger {
    display: none;
}

@media (max-width: $tablet) {
    .people-button {
        display: block;
        padding-right: 0;
    }

    // .ui-datepicker .ui-state-default {
    //     line-height: normal;
    // }
}

@media (max-width: $mobile) {
    .people-button {
        text-align: left;
        padding-left: 70px;
    }
}
